import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule, SubscriptSizing } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import ListItem from '../../../modules/shared/models/list-item.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgxMatSelectSearchModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
  ],
  selector: 'ingenix-list-item',
  standalone: true,
  styleUrls: ['./list-item.component.scss'],
  templateUrl: './list-item.component.html',
})
export class ListItemComponent {
  @Input() public label?: string;
  @Input() public listItems!: ListItem[];
  @Input() public placeholder!: string;
  @Input() public searchTermFormControl?: FormControl;
  @Input() public subscriptSizing: SubscriptSizing = 'dynamic';
  @Input() public tabindex?: number;
  @Input() public valueFormControl!: FormControl;

  public clearSelection(event: Event): void {
    event.stopPropagation();

    this.valueFormControl.patchValue(null);
  }
}
