<mat-form-field class="list-item" [appearance]="'outline'" [subscriptSizing]="subscriptSizing">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <mat-select [tabindex]="tabindex" [formControl]="valueFormControl" [placeholder]="placeholder" #singleSelect>
    @if (searchTermFormControl) {
      <mat-option>
        <ngx-mat-select-search
          [formControl]="searchTermFormControl"
          noEntriesFoundLabel="Geen zoekresultaten"
          placeholderLabel="Zoeken..."
        ></ngx-mat-select-search>
      </mat-option>
    }
    <mat-option> Selectie leegmaken </mat-option>
    @for (listItem of listItems; track listItem) {
      <mat-option [value]="listItem.id">
        {{ listItem?.description }}
      </mat-option>
    }
  </mat-select>
  @if (valueFormControl.value) {
    <button matSuffix mat-icon-button (click)="clearSelection($event)">
      <mat-icon>close</mat-icon>
    </button>
  }

  @if (valueFormControl.hasError('required')) {
    <mat-error>{{ label }} is een vereist veld</mat-error>
  }
</mat-form-field>
