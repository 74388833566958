import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import GenericResponse from 'src/app/common/models/generic-response.model';
import PagedResult from 'src/app/common/models/paged-result.model';
import { SortDirection } from 'src/app/common/models/sort-direction.enum';
import { RelationSalesDocumentType } from 'src/app/enums/relations.enum';

import ListItem from '../models/list-item.model';

@Injectable({
  providedIn: 'root',
})
export class ListItemsService {
  constructor(private readonly _http: HttpClient) {}

  public getAddresses(relationId: number, searchTerm?: string): Observable<ListItem[]> {
    const params = new URLSearchParams();
    params.append('sortField', 'addressLine1');
    params.append('sortDirection', SortDirection.Ascending);
    params.append('isBillingAddress', 'true');

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/relations/${relationId}/addresses/list?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getArticleGroups(searchTerm?: string): Observable<ListItem[]> {
    const params = new URLSearchParams();

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/article-groups/search?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getArticles(searchTerm?: string, articleGroupId?: number, supplierId?: number): Observable<ListItem[]> {
    const params = new URLSearchParams();

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    if (articleGroupId) {
      params.append('articleGroupId', articleGroupId.toString());
    }

    if (supplierId) {
      params.append('supplierId', supplierId.toString());
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/articles?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getContacts(relationId: number, searchTerm?: string): Observable<ListItem[]> {
    const params = new URLSearchParams();
    params.append('sortField', 'lastName');
    params.append('sortDirection', SortDirection.Ascending);
    params.append('isActive', 'True');
    params.append('isCustomer', 'True');

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/relations/${relationId}/contacts/list?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getCustomers(
    searchTerm?: string,
    salesDocumentType?: RelationSalesDocumentType,
    relationGroupId?: number,
  ): Observable<ListItem[]> {
    const params = new URLSearchParams();
    params.append('sortField', 'name');
    params.append('sortDirection', SortDirection.Ascending);
    params.append('isActive', 'True');
    params.append('isCustomer', 'True');

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    if (salesDocumentType) {
      params.append('salesDocumentType', salesDocumentType.toString());
    }

    if (relationGroupId) {
      params.append('relationGroupId', relationGroupId.toString());
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/relations/list?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getDangerousProducts(searchTerm?: string): Observable<ListItem[]> {
    const params = new URLSearchParams();

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/dangerous-products/list?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getDeliveryCosts(searchTerm?: string): Observable<ListItem[]> {
    const params = new URLSearchParams();
    params.append('sortField', 'name');
    params.append('sortDirection', SortDirection.Ascending);
    params.append('isActive', 'True');

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/delivery-costs/list?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getEmployees(searchTerm?: string): Observable<ListItem[]> {
    const params = new URLSearchParams();
    params.append('sortField', 'lastName');
    params.append('sortDirection', SortDirection.Ascending);
    params.append('isActive', 'True');

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/employees/list?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getLabelTemplate(id: number): Observable<ListItem> {
    return this._http
      .get<GenericResponse<ListItem>>(`/api/labels/${id}`)
      .pipe(map((response: GenericResponse<ListItem>) => response.data));
  }

  public getLabelTemplates(searchTerm?: string): Observable<ListItem[]> {
    const params = new URLSearchParams();

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/labels/search?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getPaymentMethod(searchTerm?: string): Observable<ListItem[]> {
    const params = new URLSearchParams();
    params.append('sortField', 'name');
    params.append('sortDirection', SortDirection.Ascending);
    params.append('isActive', 'True');

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/payment-methods/list?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getPriceSurgages(searchTerm?: string): Observable<ListItem[]> {
    const params = new URLSearchParams();

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/price-surgages/list?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getPrices(searchTerm?: string, isDefaultRelationPrice?: boolean): Observable<ListItem[]> {
    const params = new URLSearchParams();

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    if (isDefaultRelationPrice) {
      params.append('isDefaultRelationPrice', isDefaultRelationPrice.toString());
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/prices/list?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getRelationGroups(searchTerm?: string): Observable<ListItem[]> {
    const params = new URLSearchParams();
    params.append('sortField', 'description');
    params.append('sortDirection', SortDirection.Ascending);

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/relation-groups/list?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getRelationVatTypes(searchTerm?: string): Observable<ListItem[]> {
    const params = new URLSearchParams();
    params.append('sortField', 'name');
    params.append('sortDirection', SortDirection.Ascending);
    params.append('isActive', 'True');

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/relation-vat-types/list?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getRelations(searchTerm?: string, relationGroupId?: number): Observable<ListItem[]> {
    const params = new URLSearchParams();
    params.append('sortField', 'name');
    params.append('sortDirection', SortDirection.Ascending);
    params.append('isActive', 'True');

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    if (relationGroupId) {
      params.append('relationGroupId', relationGroupId.toString());
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/relations/list?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getStockTransactionReasons(searchTerm?: string): Observable<ListItem[]> {
    const params = new URLSearchParams();
    params.append('sortField', 'reason');
    params.append('sortDirection', SortDirection.Ascending);

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/stock-transaction-reasons/list?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getSuppliers(searchTerm?: string): Observable<ListItem[]> {
    const params = new URLSearchParams();
    params.append('sortField', 'name');
    params.append('sortDirection', SortDirection.Ascending);
    params.append('isActive', 'True');
    params.append('isSupplier', 'True');

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/relations/list?${params}`)
      .pipe(map(result => result.data.data));
  }

  public getVatPercentages(searchTerm?: string): Observable<ListItem[]> {
    const params = new URLSearchParams();
    params.append('sortField', 'name');
    params.append('sortDirection', SortDirection.Ascending);
    params.append('isActive', 'True');

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    return this._http
      .get<GenericResponse<PagedResult<ListItem>>>(`/api/vat-percentages/list?${params}`)
      .pipe(map(result => result.data.data));
  }
}
